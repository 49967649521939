import { Injectable } from '@angular/core';
import { AwtError } from '../models';
import { RuntimeError } from '../custom-errors/runtime-error';
import { CustomError } from '../custom-errors/models';
import { ZoneError } from '../custom-errors/zone-error';
import { UnknownError } from '../custom-errors/unknown-error';
import { BaseCustomError } from '../custom-errors/base-custom.error';

@Injectable({ providedIn: 'root' })
export class ErrorFactoryService {
  constructor() {}

  createError(error: CustomError | AwtError): CustomError {
    if (error instanceof BaseCustomError) {
      return error;
    }

    let customError: CustomError;

    if (error instanceof Error) {
      if (error.name === 'ZoneAwareError' || error.message.includes('Zone')) {
        customError = new ZoneError(error);
      } else {
        customError = new RuntimeError(error, 'unknown');
      }
    } else if (error instanceof globalThis.ErrorEvent) {
      customError = new RuntimeError(new Error(error.message), error.filename || 'unknown');
    } else {
      customError = new UnknownError(error);
    }

    return customError;
  }
}
