import { InjectionToken } from '@angular/core';
import { ErrorHandlerConfig, CustomErrorType } from '../custom-errors/models';

export const DEFAULT_ERROR_HANDLER_CONFIG: ErrorHandlerConfig = {
  loggingConfig: {
    enabled: true,
    excludedTypes: [],
  },
  notificationConfig: {
    enabled: true,
    includedTypes: [CustomErrorType.ui, CustomErrorType.http, CustomErrorType.unknown],
  },
  backendPublishConfig: {
    enabled: true,
    excludedTypes: [CustomErrorType.network],
  },
};

export const ERROR_HANDLER_CONFIG = new InjectionToken<ErrorHandlerConfig>('ERROR_HANDLER_CONFIG', {
  providedIn: 'root',
  factory: () => DEFAULT_ERROR_HANDLER_CONFIG,
});
