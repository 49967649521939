import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorService } from './error-service/error.service';
import { HttpErrorInterceptor } from './interceptor/http-error.interceptor';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    ErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
})
export class ErrorHandlingModule {}
