import { CustomErrorDetails } from '../models';
import { CustomError, CustomErrorType } from './models';

export abstract class BaseCustomError extends Error implements CustomError {
  abstract customErrorType: CustomErrorType;
  timestamp: number;
  details: CustomErrorDetails;
  constructor(message: string) {
    super(message);
  }
}
