/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendAPIErrorBody, ErrorPublishResponse, ServiceType } from '../models';
import { CustomError } from '../custom-errors/models';
import { AuthService } from '@core/services/auth.service';
import { APP_CONFIG } from '@app/core/services/app-config';
import { AppConfig } from '@app/core/services/app-config';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private readonly notificationAction = 'sendSystemErrorNotification';
  private readonly serviceName = 'article-editor';

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly snackbar: SnackbarService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  private get apiUrl(): string {
    return `${this.config.apiUrl}/event-dispatcher/notifications`;
  }

  public publishToBackend(error: CustomError): Observable<ErrorPublishResponse> {
    const backendAPIErrorBody = this.buildBackendAPIErrorBody(error);
    return this.http.post(this.apiUrl, backendAPIErrorBody);
  }

  public log(error: CustomError): void {
    console.error(error);
  }

  public notifyUser(error: CustomError): void {
    this.snackbar.error(error.message);
  }

  private buildBackendAPIErrorBody(error: CustomError): BackendAPIErrorBody {
    return {
      action: this.notificationAction,
      service_name: this.serviceName,
      service_id: 'NO_ACCESS_TO_ENV_VARIABLES', // TODO: we don't have access to the environment variables here
      message: error.message,
      user_id: this.authService.currentUser?.id,
      timestamp: error.timestamp,
      service_type: ServiceType.frontend,
      details: error.details,
    };
  }
}
