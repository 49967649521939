import { Injectable } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable, Subject, Subscription, combineLatest, interval } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Comment, CommentFilter, YdocCommentThread } from '../../comment.models';
import { CommentsService } from '@app/editor/utils/commentsService/comments.service';
import { CommentsActions, CommentsSelectors } from '@app/store/comments';
import { CommentSelectionState } from '@app/store/comments/models';
import { AuthService } from '@app/core/services/auth.service';
import { updateCommentsFilter } from '@app/store/comments/comments.actions';

@Injectable({
  providedIn: 'root',
})
export class CommentsStateService {
  isSearching = false;
  searchForm = new UntypedFormControl('');
  subscription$ = new Subscription();
  allComments: Comment[] = [];
  users: string[] = [];
  sortingFormGroup: UntypedFormGroup;
  lastSelectedComment: CommentSelectionState | null = null;
  showResolved = false;
  updateFilter$ = new Subject<void>();

  private destroy$ = new Subject<void>();
  private _filteredComments: { inydoc: YdocCommentThread; pmmark: Comment }[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private commentsService: CommentsService,
    private authService: AuthService
  ) {
    this.store.select(CommentsSelectors.selectFilteredComments).subscribe((comments) => {
      this._filteredComments = comments;
    });
    this.sortingFormGroup = this.formBuilder.group({
      showResolved: [false],
      byCreators: this.formBuilder.array([]),
      iAmMentioned: [false],
    });
  }

  get filteredComments(): { inydoc: YdocCommentThread; pmmark: Comment }[] {
    return this._filteredComments;
  }

  get arrayControls(): UntypedFormControl[] {
    return (this.sortingFormGroup.get('byCreators') as UntypedFormArray)
      .controls as UntypedFormControl[];
  }

  private get sortingChanges$(): Observable<CommentFilter> {
    return this.sortingFormGroup.valueChanges.pipe(
      startWith(this.sortingFormGroup.value),
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    );
  }

  private get searchChanges$(): Observable<string> {
    return this.searchForm.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged()
    );
  }

  private get selectedCommentChanges$(): Observable<CommentSelectionState> {
    return this.store.select(CommentsSelectors.selectLastSelectedComment).pipe(
      debounce(() => interval(300)),
      distinctUntilChanged(
        (prev, curr) =>
          prev.commentId === curr.commentId &&
          prev.commentMarkId === curr.commentMarkId &&
          prev.sectionId === curr.sectionId
      ),
      startWith({})
    );
  }

  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription$.unsubscribe();
  }

  watchSearchAndFilteringChanges(): void {
    this.watchCommentsChanges();
    let previouslyHadFilters = false;

    this.subscription$.add(
      combineLatest([
        this.sortingChanges$,
        this.searchChanges$,
        this.selectedCommentChanges$,
      ]).subscribe(([filter, search, lastSelected]) => {
        const searchText = (search?.trim() || '').toLowerCase();
        this.showResolved = filter.showResolved;
        this.lastSelectedComment = lastSelected;
        this.isSearching = !!searchText;

        const hasFilters = this.hasActiveFilters(filter, searchText);

        if (hasFilters && this.filteredComments.length > 0) {
          const firstComment = this.filteredComments[0];
          this.store.dispatch(
            CommentsActions.setLastSelectedComment({
              commentId: firstComment.pmmark.commentAttrs.id,
              commentMarkId: firstComment.pmmark.commentMarkId,
              sectionId: firstComment.pmmark.section,
            })
          );
        }

        previouslyHadFilters = hasFilters;

        this.store.dispatch(
          updateCommentsFilter({
            filter: {
              showResolved: filter.showResolved,
              byCreators: filter.byCreators,
              iAmMentioned: filter.iAmMentioned,
              search: searchText,
            },
          })
        );

        this.updateFilteredComments(searchText, filter);
      })
    );
  }

  private watchCommentsChanges(): void {
    this.subscription$.add(
      this.updateFilter$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        const filter = this.sortingFormGroup.value;
        const searchText = (this.searchForm.value?.trim() || '').toLowerCase();
        this.updateFilteredComments(searchText, filter);
      })
    );
  }

  private hasActiveFilters(filter: CommentFilter, searchText: string): boolean {
    return (
      filter.showResolved ||
      filter.iAmMentioned ||
      filter.byCreators?.some((creator) => creator) ||
      !!searchText
    );
  }

  private updateFilteredComments(searchText: string, filter: CommentFilter): void {
    const commentsInYdocMap = this.commentsService.getCommentsFromYdoc();
    const sortedComments = this.getSortedComments();
    const mappedComments = sortedComments.map((com) => ({
      inydoc: commentsInYdocMap[com.commentAttrs.id],
      pmmark: com,
    }));

    const filteredComments = this.filterCommentsByConditions(mappedComments, filter, searchText);

    // Create a deep copy for the store
    const filteredCommentsForStore = JSON.parse(JSON.stringify(filteredComments));

    this.store.dispatch(
      CommentsActions.setFilteredComments({ comments: filteredCommentsForStore })
    );
  }

  private filterCommentsByConditions(
    comments: { inydoc: YdocCommentThread; pmmark: Comment }[],
    filter: CommentFilter,
    searchText: string
  ): { inydoc: YdocCommentThread; pmmark: Comment }[] {
    return comments
      .filter((comment) => this.filterResolved(comment, filter.showResolved))
      .filter((comment) => this.matchesUserFilters(comment, filter))
      .filter((comment) => this.matchesSearchText(comment, searchText));
  }

  private filterResolved(
    comment: { inydoc: YdocCommentThread; pmmark: Comment },
    showResolved: boolean
  ): boolean {
    return showResolved ? true : comment.pmmark.resolved !== 'true';
  }

  private matchesSearchText(
    comment: { inydoc: YdocCommentThread; pmmark: Comment },
    searchText: string
  ): boolean {
    return !searchText ? true : this.isCommentMatchingSearch(comment, searchText);
  }

  private isCommentMatchingSearch(data: { inydoc: YdocCommentThread }, searchVal: string): boolean {
    const { initialComment, commentReplies } = data.inydoc;

    return (
      initialComment.comment.toLowerCase().includes(searchVal) ||
      initialComment.userData.email.toLowerCase().includes(searchVal) ||
      initialComment.userData.name.toLowerCase().includes(searchVal) ||
      commentReplies.some(
        (reply) =>
          reply.comment.toLowerCase().includes(searchVal) ||
          reply.userData.email.toLowerCase().includes(searchVal) ||
          reply.userData.name.toLowerCase().includes(searchVal)
      )
    );
  }

  private matchesUserFilters(
    comment: { inydoc: YdocCommentThread; pmmark: Comment },
    filter: CommentFilter
  ): boolean {
    if (!filter.iAmMentioned && !filter.byCreators?.some((c) => c)) {
      return true;
    }

    const userEmail = this.authService.userInfo.data.email;
    return (
      (filter.iAmMentioned && this.isUserMentioned(comment, userEmail)) ||
      filter.byCreators?.some(
        (creator, i) => creator && this.users[i] === comment.inydoc.initialComment.userData.name
      )
    );
  }

  private isUserMentioned(data: { inydoc: YdocCommentThread }, userEmail: string): boolean {
    return (
      data.inydoc.initialComment.comment.includes(userEmail) ||
      data.inydoc.commentReplies.some((reply) => reply.comment.includes(userEmail))
    );
  }

  private getSortedComments(): Comment[] {
    return [...this.allComments].sort((c1, c2) => {
      if (c1.domTop !== c2.domTop) {
        return c1.domTop - c2.domTop;
      }
      return c1.pmDocStartPos - c2.pmDocStartPos;
    });
  }
}
