import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackWidgetComponent } from './components/feedback-widget/feedback-widget.component';
import { FEATUREBASE_CONFIG, FeaturebaseConfig } from './models/featurebase.models';

@NgModule({
  declarations: [FeedbackWidgetComponent],
  imports: [CommonModule],
  exports: [FeedbackWidgetComponent],
})
export class UserFeedbackModule {
  static forRoot(featurebaseConfig: FeaturebaseConfig): ModuleWithProviders<UserFeedbackModule> {
    return {
      ngModule: UserFeedbackModule,
      providers: [{ provide: FEATUREBASE_CONFIG, useValue: featurebaseConfig }],
    };
  }
}
