import { Injectable, Inject, NgZone, OnDestroy } from '@angular/core';
import { Observable, of, throwError, Subject } from 'rxjs';
import { ScriptLoaderService } from '@core/services/script-loader/script-loader.service';
import {
  FEATUREBASE_CONFIG,
  FeaturebaseConfig,
  FeaturebaseUser,
} from '../models/featurebase.models';
import { tap, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeaturebaseService implements OnDestroy {
  private initialized = false;
  private readonly scriptUrl = 'https://do.featurebase.app/js/sdk.js';
  private destroy$ = new Subject<void>();

  constructor(
    private scriptLoaderService: ScriptLoaderService,
    private ngZone: NgZone,
    @Inject(FEATUREBASE_CONFIG) private featurebaseConfig: FeaturebaseConfig
  ) {
    this.initializeFeaturebaseQueue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeWidget(): Observable<void> {
    if (this.initialized) {
      return of(undefined);
    }

    return this.scriptLoaderService.loadScript('featurebase', this.scriptUrl).pipe(
      tap(() => {
        this.ngZone.run(() => {
          try {
            this.initialized = true;
            this.initializeFeedbackWidget();
          } catch (error) {
            console.error('Featurebase initialization failed:', error);
            this.initialized = false;
          }
        });
      }),
      catchError((error) => {
        console.error('Featurebase script load failed:', error);
        this.initialized = false;
        return throwError(new Error('Failed to load Featurebase'));
      })
    );
  }

  identifyUser(user: FeaturebaseUser): void {
    if (!user || !this.initialized) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      window.Featurebase?.('identify', {
        organization: this.featurebaseConfig.featurebaseId,
        email: user.email,
        name: user.name,
        id: user.id,
      });
    });
  }

  private initializeFeedbackWidget(): void {
    this.ngZone.runOutsideAngular(() => {
      window.Featurebase?.('initialize_feedback_widget', {
        organization: this.featurebaseConfig.featurebaseId,
        theme: 'light',
        placement: 'right',
        locale: 'en',
      });
    });
  }

  private initializeFeaturebaseQueue(): void {
    this.ngZone.runOutsideAngular(() => {
      window.Featurebase =
        window.Featurebase ||
        function (...args: unknown[]) {
          (window.Featurebase.q = window.Featurebase.q || []).push(args);
        };
    });
  }
}
