import { CustomErrorType } from './models';
import { BaseCustomError } from './base-custom.error';
import { UnknownErrorDetails } from './models';
export class UnknownError extends BaseCustomError {
  public customErrorType: CustomErrorType.unknown;
  public timestamp: number;
  public details: UnknownErrorDetails;

  constructor(
    public error: unknown,
    public userMessage: string = 'An unexpected error occurred.'
  ) {
    super('Unknown Error');
    Error.captureStackTrace(this, UnknownError);
    this.customErrorType = CustomErrorType.unknown;
    this.timestamp = Date.now();
    this.details = {
      error,
      userMessage,
    };
  }
}
