import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { ErrorService } from './error-handling/error-service/error.service';
import { AuthService } from './services/auth.service';
import { EchoService } from './services/echo/echo.service';
import { UserFeedbackModule } from './user-feedback/user-feedback.module';
import { defaultFeaturebaseConfig } from './user-feedback/models/featurebase.models';

@NgModule({
  imports: [
    CommonModule,
    ErrorHandlingModule,
    UserFeedbackModule.forRoot(defaultFeaturebaseConfig),
  ],
  exports: [ErrorHandlingModule, UserFeedbackModule],
  providers: [ErrorService, AuthService, EchoService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
