import { Injectable, ErrorHandler, Inject } from '@angular/core';
import { ErrorService } from '../error-service/error.service';
import { ErrorFactoryService } from '../error-factory/error-factory.service';
import { CustomError } from '../custom-errors/models';
import { AppConfig, APP_CONFIG } from '@core/services/app-config';
import { ErrorHandlerConfig } from '../custom-errors/models';
import { ERROR_HANDLER_CONFIG } from './error-handler.config';
import { AwtError } from '../models';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly errorService: ErrorService,
    private readonly errorFactory: ErrorFactoryService,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    @Inject(ERROR_HANDLER_CONFIG) private readonly config: ErrorHandlerConfig
  ) {}

  public handleError(error: AwtError): void {
    try {
      const customError = this.errorFactory.createError(error);

      if (this.shouldLog(customError)) {
        this.handleLogging(customError);
      }

      if (this.shouldNotifyUser(customError)) {
        this.handleNotification(customError);
      }

      if (this.shouldSendToBackend(customError)) {
        this.handleErrorSending(customError);
      }
    } catch (e) {
      console.error('Error in error handler:', e);
    }
  }

  private shouldLog(error: CustomError): boolean {
    return (
      this.config.loggingConfig.enabled &&
      !this.appConfig.production &&
      !this.config.loggingConfig.excludedTypes?.includes(error.customErrorType)
    );
  }

  private handleLogging(error: CustomError): void {
    this.errorService.log(error);
  }

  private shouldNotifyUser(error: CustomError): boolean {
    return (
      this.config.notificationConfig.enabled &&
      this.config.notificationConfig.includedTypes.includes(error.customErrorType)
    );
  }

  private handleNotification(error: CustomError): void {
    this.errorService.notifyUser(error);
  }

  private shouldSendToBackend(error: CustomError): boolean {
    return (
      this.config.backendPublishConfig.enabled &&
      !this.config.backendPublishConfig.excludedTypes.includes(error.customErrorType)
    );
  }

  private handleErrorSending(error: CustomError): void {
    this.errorService
      .publishToBackend(error)
      .pipe(
        catchError((err) => {
          // TODO: ensure these are not caught by the interceptor
          console.error('Failed to send error to backend:', err);
          return of(null);
        })
      )
      .subscribe();
  }
}
