import { InjectionToken } from '@angular/core';

export interface FeaturebaseUser {
  id: string;
  email: string;
  name: string;
}

export interface FeaturebaseCompany {
  id: string;
  name: string;
}

export interface FeaturebaseConfig {
  featurebaseId: string;
  defaultUser?: FeaturebaseUser;
  company?: FeaturebaseCompany;
}

export const FEATUREBASE_CONFIG = new InjectionToken<FeaturebaseConfig>('FEATUREBASE_CONFIG');

export const defaultFeaturebaseConfig: FeaturebaseConfig = {
  featurebaseId: 'pensoft', // Change this when switching to a new featurebase project
  defaultUser: {
    id: `anon_${Date.now()}`,
    email: 'anonymous@pensoft.net',
    name: 'Anonymous User',
  },
  company: {
    id: 'pensoft',
    name: 'Pensoft Publishers',
  },
};
