import { CustomErrorType } from './models';
import { BaseCustomError } from './base-custom.error';

export class UiError extends BaseCustomError {
  public customErrorType: CustomErrorType.ui;
  public timestamp: number;
  public details: {
    componentName: string;
    userAction: string;
    userMessage: string;
  };

  constructor(
    public componentName: string,
    public userAction: string,
    public userMessage: string = 'A UI error occurred.'
  ) {
    super(`UI Error in component: ${componentName}`);
    this.customErrorType = CustomErrorType.ui;
    this.timestamp = Date.now();
    this.details = {
      componentName,
      userAction,
      userMessage,
    };
  }
}
